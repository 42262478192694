export default {
  props: {
    type: String,
    data: Object,
    layout: Object,
    panel: Object,
    properties: Object,
    deviceData: [Object, Array, String],
  },
};
