<template>
  <div
    class="d-flex justify-center align-center"
    style="height:100%"
  >
    <div
      :style="valueStyle"
    >
      <!-- energy data -->
      <span
        v-if="energyData"
        class="font-italic"
      >
        <v-icon color="warning">
          mdi-flash
        </v-icon>
      </span>
      <span class="font-weight-black">{{ value }}</span>
      <template v-if="properties.show_value_unit">
        <!-- latest payload data -->
        <span
          v-if="!energyData"
          class="font-italic"
        > {{ underscoreToSpace(data.payload_keys[0]) }} </span>
      </template>
    </div>
  </div>
</template>

<script>
import { floatPrecision, underscoreToSpace } from '../../../utils/utils';
import WidgetMixin from '../../../mixins/WidgetMixin';

export default {
  name: 'ValueWidget',
  mixins: [WidgetMixin],

  computed: {
    energyData() {
      if (this.data.source === 'energy' && this.deviceData.payloads.length) {
        return this.deviceData.payloads[0];
      }
      return null;
    },

    value() {
      if (this.energyData) {
        return Number(floatPrecision(this.energyData.cumulative_value, 2));
      }
      if (this.deviceData.latest_payload) {
        return Number(floatPrecision(this.deviceData.latest_payload[this.data.payload_keys], 2));
      }
      return 0;
    },

    valueStyle() {
      return {
        'font-size': `${this.properties.size}px`,
      };
    },
  },

  methods: {
    underscoreToSpace,
  },

};
</script>
